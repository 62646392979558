import { configs } from '@/utils/configs'
import { translation } from '@/utils/translation.js'

import { Dom7 } from 'framework7'

import { f7 } from 'framework7-vue'

import { cloneDeep } from 'lodash'

import Masonry from 'masonry-layout'

import { useStore } from '@/store'
const store = useStore()

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

let helpers = {
  checkIsMobile() {
    if (navigator?.userAgent && /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent)) {
      return true
    }

    return false
  },
  encodeFileName(file) {
    return encodeURIComponent(file).replace(/'/g, '%27')
  },
  cutText(text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  padStart(value, numberOfPad, padChar) {
    let pattern = ''
    for (let i = 0; i < numberOfPad; i++) {
      pattern += padChar
    }

    return (pattern + value).slice(-numberOfPad)
  },
  formatPHMobile({ MobileCode = '+63', MobileNumber }) {
    return `${MobileCode} ${MobileNumber.substring(0, 3)} ${MobileNumber.substring(3, 7)} ${MobileNumber.substring(7, 10)}`
  },
  formatDateFromNow(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).fromNow()
    } else {
      return '&mdash;'
    }
  },
  formatDate(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('MMM D, YYYY')
    } else {
      return '&mdash;'
    }
  },
  formatSQLDate(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('YYYY-MM-DD')
    } else {
      return '&mdash;'
    }
  },
  formatJSDate(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('YYYY-MM-DD')
    } else {
      return '&mdash;'
    }
  },
  formatDateTime(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('MMM D, YYYY h:mm A')
    } else {
      return '&mdash;'
    }
  },
  formatPascalCase(str) {
    return (
      str
        // Look for long acronyms and filter out the last letter
        .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
        // Look for lower-case letters followed by upper-case letters
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        // Look for lower-case letters followed by numbers
        .replace(/([a-zA-Z])(\d)/g, '$1 $2')
        .replace(/^./, (str) => {
          return str.toUpperCase()
        })
        // Remove any white space left around the word
        .trim()
    )
  },
  formatUpperSnakeCase(str) {
    return str
      .replace(/^.+\./, '')
      .split(/(?=[A-Z])/)
      .join('_')
      .toUpperCase()
  },
  formatConfigValue(str) {
    return helpers.formatPascalCase(str.replace(/^.+\./, ''))
  },
  formatTrimString(str, count = 255, append = '...') {
    if (str && str != '') {
      str = str.replace(/(<([^>]+)>)/gi, '')
      str = str.replace(/&nbsp;/g, ' ')
    }

    if (str && str.length > count) {
      return str.substring(0, count) + append
    } else {
      return str
    }
  },
  formatFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }

    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
  },
  formatField(field, data) {
    let returnField = ''

    switch (field.format) {
      case 'date':
        returnField = helpers.formatDate(data?.[field?.field])
        break
      case 'datetime':
        returnField = helpers.formatDateTime(data?.[field?.field])
        break
      case 'uppercase':
        returnField = data?.[field?.field]?.toUpperCase()
        break
      case 'lowercase':
        returnField = data?.[field?.field]?.toLowerCase()
        break
      case 'number':
        returnField = parseInt(data?.[field?.field]) || field.fallback
        break
      case 'decimal':
        returnField = parseFloat(data?.[field?.field])
        break
      case 'image':
        returnField = helpers.getImage(data?.[field?.field], field?.bucket)
        break
      case 'download':
        returnField = helpers.getDownloadLink(data?.[field?.field], field?.bucket)
        break
      case 'yesno':
        returnField = parseInt(data?.[field?.field]) >= 1 ? translation.getTranslation('PNL_YES').toUpperCase() : translation.getTranslation('PNL_NO').toUpperCase()
        break
      case 'namejoin':
        let tmpData = []
        data?.[field?.field].forEach((item) => tmpData.push(item.name))
        returnField = tmpData.join(', ')
        break
      default:
        returnField = data?.[field?.field]
    }

    if (helpers.isBlank(returnField)) {
      returnField = field?.placeholder ? field.placeholder : '&mdash;'
    }

    return returnField
  },
  checkTableButtonEnabled(button, data) {
    let isEnabled = true

    if (typeof button?.isEnabled === 'function') {
      isEnabled = button?.isEnabled(data)
    }

    if (button.type === 'DELETE' && (data?.StatusCode === 'DELETED' || data?.UserStatusCode === 'DELETED')) {
      isEnabled = false
    }

    return isEnabled
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  isSet(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  isBlank: (value) => {
    if (value === null || value === undefined || value === '' || value.length === 0) {
      return true
    } else {
      return false
    }
  },
  isValidTableId(val) {
    return val && parseInt(val) > 0
  },
  isUndefined(val) {
    return typeof val === 'undefined'
  },
  isInteger(val) {
    return helpers.isUndefined(val) ? false : Number.isInteger(!isNaN(val) ? Number(val) : null)
  },
  isNumber(val) {
    return helpers.isUndefined(val) ? false : isNaN(parseFloat(val)) ? false : true
  },
  isString(val) {
    return typeof val === 'string' || val instanceof String
  },
  isObject(val) {
    return !helpers.isUndefined(val) && val.toString() === '[object Object]'
  },
  isArray(val) {
    return val instanceof Array
  },
  isEmpty(val) {
    return helpers.isBlank(val) || val.length <= 0 || Object.keys(val).length <= 0
  },
  isJSON(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }

    return true
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  randomNumbers(from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  getFullName(user, showMiddleName = false) {
    let fullName = ''

    if (user && user.FirstName) {
      fullName += user.FirstName
    }

    if (showMiddleName && user && user.MiddleName) {
      fullName += ` ${user.MiddleName}`
    }

    if (user && user.LastName) {
      fullName += ` ${user.LastName}`
    }

    return fullName
  },
  getFullAddress(item) {
    let Address = ''
    if (item && item.Street) {
      Address += item.Street + ', '
    }

    if (item && item.Barangay) {
      Address += (item.Barangay.toLowerCase().indexOf('barangay') > -1 ? '' : 'Barangay ') + item.Barangay + ', '
    }

    if (item && item.City) {
      Address += item.City + (item.Barangay.toLowerCase().indexOf('city') > -1 ? '' : ' City') + ', '
    }

    if (item && item.Province) {
      Address += item.Province + ', '
    }

    if (item && item.Country) {
      Address += item.Country + ', '
    }

    if (item && item.ZipCode) {
      Address += item.ZipCode
    }

    return Address
  },
  renderImageBlob(blob) {
    if (blob instanceof Blob) {
      const objectURL = URL.createObjectURL(blob)
      return objectURL
    } else {
      return false
    }
  },
  getDownloadLink(item, type, link = 'download') {
    let fileLink = `${configs.baseURL}/${link}?name=${item}&type=${type}`
    return fileLink
  },
  getItemLink(item, type, link = 'image') {
    if (helpers.isString(item) && !helpers.isBlank(item)) {
      return `${configs.baseURL}/${link}?name=${helpers.encodeFileName(item)}&type=${type}`
    } else if (item && helpers.isObject(item) && item.Image) {
      return `${configs.baseURL}/${link}?name=${helpers.encodeFileName(item.Image)}&type=${type}`
    } else if (item && helpers.isObject(item) && item.Name) {
      return `${configs.baseURL}/${link}?name=${helpers.encodeFileName(item.Name)}&type=${type}`
    } else {
      return false
    }
  },
  getImage(item, type, placeholder, isBase64) {
    if (isBase64) {
      return item
    }

    let itemLink = helpers.getItemLink(item, type, 'image')

    if (itemLink) {
      return itemLink
    } else {
      return placeholder && placeholder !== '' ? placeholder : require('@/assets/images/placeholder.jpg')
    }
  },
  getStyleImage(item, type, placeholder) {
    let imageLink = helper.getImage(item, type, placeholder)
    return 'url(' + imageLink + ')'
  },
  getVideo(item, type, placeholder) {
    let itemLink = helpers.getItemLink(item, type, 'video')

    if (itemLink) {
      return itemLink
    } else {
      return placeholder && placeholder !== '' ? placeholder : require('@/assets/images/placeholder.jpg')
    }
  },
  openImage(imageLink) {
    const imageList = [imageLink]
    this.openImageList(imageList)
  },
  openImageList(imageList) {
    const photoBrowser = f7.photoBrowser.create({
      photos: imageList,
      routableModals: false,
      popupCloseLinkText: '<i class="far fa-fw fa-xmark"></i>',
      type: 'popup',
      popupPush: true,
      toolbar: imageList.length > 1
    })

    photoBrowser.open()

    photoBrowser.on('close', function () {
      if (Dom7('.popup.modal-in').length > 0) Dom7('.popup-backdrop').addClass('backdrop-in')
      photoBrowser.destroy()
    })
  },
  objToQueryString(obj, link) {
    const keyValuePairs = []
    for (let i = 0; i < Object.keys(obj).length; i += 1) {
      if (typeof Object.values(obj)[i] === 'undefined') continue
      keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`)
    }

    if (keyValuePairs.length == 0) return ''
    return link && link.indexOf('?') > -1 ? '&' + keyValuePairs.join('&') : '?' + keyValuePairs.join('&')
  },
  showLoader() {
    f7.preloader.show()
  },
  hideLoader() {
    f7.preloader.hide()
  },
  showLoaderText(text = 'Loading...') {
    f7.dialog.preloader(text)
  },
  hideLoaderText() {
    f7.dialog.close()
  },
  createTimePicker(timePickerElement, onChange, { hasSeconds = true }) {
    let columnsDeclarations = [
      {
        textAlign: 'center',
        values: (() => {
          var arr = []
          for (var i = 0; i <= 24; i++) {
            arr.push(i < 10 ? '0' + i : i)
          }
          return arr
        })()
      },
      {
        divider: true,
        content: ':'
      },
      {
        textAlign: 'center',
        values: (() => {
          var arr = []
          for (var i = 0; i <= 60; i++) {
            arr.push(i < 10 ? '0' + i : i)
          }
          return arr
        })()
      }
    ]

    if (hasSeconds) {
      columnsDeclarations.push({
        divider: true,
        content: ':'
      })

      columnsDeclarations.push({
        textAlign: 'center',
        values: (() => {
          var arr = []
          for (var i = 0; i <= 60; i++) {
            arr.push(i < 10 ? '0' + i : i)
          }
          return arr
        })()
      })
    }

    Dom7(timePickerElement).forEach((element) => {
      f7.picker.create({
        inputEl: element,
        rotateEffect: true,
        cols: columnsDeclarations,
        on: {
          change: onChange
        }
      })
    })
  },
  createDurationPicker(durationPickerElement, onChange) {
    Dom7(durationPickerElement).forEach((element) => {
      let parentElement = Dom7(element).parents('.durationpicker')
      let elementIncrement = parseInt(parentElement.attr('increment'))
      let elementMaxHour = parseInt(parentElement.attr('maxhour'))

      let increment = elementIncrement > 0 ? elementIncrement : 5
      let maxHour = elementMaxHour > 0 ? elementMaxHour : 24

      f7.picker.create({
        inputEl: element,
        rotateEffect: true,
        cols: [
          {
            textAlign: 'center',
            values: (() => {
              var arr = []
              for (var i = 0; i <= maxHour; i++) {
                arr.push(i < 10 ? '0' + i : i)
              }
              return arr
            })()
          },
          {
            divider: true,
            content: ':'
          },
          {
            textAlign: 'center',
            values: (() => {
              var arr = []
              for (var i = 0; i < 60; i += increment) {
                arr.push(i < 10 ? '0' + i : i)
              }
              return arr
            })()
          }
        ],
        on: {
          change: onChange
        }
      })
    })
  },
  createDatePicker(datePickerElement, onChange) {
    let today = new Date()
    let monthMap = 'January February March April May June July August September October November December'.split(' ')

    Dom7(datePickerElement).forEach((element) => {
      let startYear = Dom7(element).parents('li').attr('data-start-year') || today.getFullYear()

      let defaultDate = Dom7(element).parents('li').attr('data-default-date') || null

      if (defaultDate === 'today') {
        defaultDate = [monthMap[today.getMonth()], today.getDate(), today.getFullYear()]
      }

      f7.picker.create({
        inputEl: element,
        rotateEffect: true,
        value: defaultDate,
        cols: [
          {
            values: monthMap,
            textAlign: 'left'
          },
          {
            values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
            textAlign: 'center'
          },
          {
            values: (function () {
              var arr = []
              for (var i = startYear; i <= today.getFullYear() + 10; i++) {
                arr.push(i)
              }
              return arr
            })()
          }
        ],
        on: {
          change: onChange
        }
      })
    })
  },
  createDateTimePicker(dateTimePickerElement, onChange) {
    Dom7(dateTimePickerElement).forEach((element) => {
      f7.calendar.create({
        inputEl: element,
        timePicker: true,
        dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
        on: {
          change: onChange
        }
      })
    })
  },
  createNotification({ type, title, message, time }) {
    title = title || translation.getTranslation('PNL_NOTIFICATION')
    message = message || translation.getTranslation('PNL_CLICK_TO_CLOSE')
    time = time || translation.getTranslation('PNL_NOW')

    let notificationCreated = false
    if (!notificationCreated) {
      const notification = f7.notification.create({
        title: configs.title,
        titleRightText: time,
        subtitle: `<strong class="${type}">${title}</strong>`,
        text: message,
        closeTimeout: 3000,
        closeOnClick: true
      })

      notification.open()

      notification.on('open', function () {
        notificationCreated = true
      })

      notification.on('close', function () {
        notification.destroy()
        notificationCreated = false
      })
    }
  },
  createConfirmation({ message, title, confirm, cancel }) {
    title = title || translation.getTranslation('PNL_CONFIRM')
    f7.dialog.confirm(message, title, confirm, cancel)
  },
  createRemarks({ message, title, confirm, cancel }) {
    title = title || translation.getTranslation('PNL_CONFIRM')
    f7.dialog.prompt(message, title, confirm, cancel)
  },
  catchError(err, showNotifications = false) {
    if (err.Message || err.Code) {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: translation.getTranslation('ERR_ERROR'),
          message: err.Message || err.Code
        })
      }
    } else if (err && err.response && err.response.data && (err.response.data.Message || err.response.data.Code)) {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: translation.getTranslation('ERR_ERROR'),
          message: err.response.data.Message || err.response.data.Code
        })
      }
    } else if (err && err.message) {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: translation.getTranslation('ERR_ERROR'),
          message: err.message
        })
      }
    } else {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: translation.getTranslation('ERR_ERROR'),
          message: translation.getTranslation('ERR_SERVER_NOT_RESPONDING')
        })
      }
    }

    if (err?.Code === 'ERR_INVALID_TOKEN' || err?.response?.data?.Code === 'ERR_INVALID_TOKEN') {
      store.dispatch('user/resetData')
      window.location.href = '/login/'
    }
  },
  copyToClipBoard(text) {
    var element = document.createElement('textarea')
    element.value = text
    element.setAttribute('readonly', '')
    element.style = { position: 'absolute', left: '-9999px' }

    document.body.appendChild(element)
    element.select()

    document.execCommand('copy')
    document.body.removeChild(element)
  },
  clearFormData(formData) {
    for (let keys in formData) {
      formData[keys] = ''
    }

    return true
  },
  deleteFormData(formData) {
    for (let keys in formData) {
      delete formData[keys]
    }

    return true
  },
  resetForm(formElement) {
    if (formElement && Dom7(formElement).length > 0) Dom7(formElement)[0].reset()

    setTimeout(() => {
      const inputs = Dom7(formElement).find('.item-input-invalid')

      inputs.forEach((input) => {
        Dom7(input).removeClass('item-input-invalid')
      })
    }, 100)
  },
  getDeviceWidth() {
    return Dom7('body')[0].offsetWidth
  },
  getFieldInfo(input) {
    let info = input.info || (input?.required ? translation.getTranslation('PNL_FIELD_REQUIRED_TO_PROCEED') : '')
    if (info == '') {
      switch (input.value) {
        case 'Description':
          info = translation.getTranslation('PNL_MAXIMUM_CHARACTER_LIMIT')
          break
        case 'Position':
          info = translation.getTranslation('PNL_POSITION_ARE_USE_IN_SORTING')
          break
        default:
          info = ''
      }
    }

    return info
  },
  getStatusColor(status) {
    let chipColor = ''
    switch (status) {
      case 'PENDING':
        chipColor = 'color-gray'
        break
      case 'ACTIVE':
        chipColor = 'color-primary'
        break
      case 'DISABLED':
        chipColor = 'color-orange'
        break
      case 'DELETED':
      case 'ONHOLD':
      case 'TERMINATED':
        chipColor = 'color-red'
        break
      default:
        chipColor = 'color-primary'
    }

    return chipColor
  },
  computePercentage(amount, percentage) {
    let value = (amount / 100) * percentage
    value = parseFloat(value.toPrecision(12))
    value = Math.round(value * 100) / 100

    return value
  },
  stringInterpolate(string, data) {
    return string.replace(/{([^{}]*)}/g, function (a, b) {
      let r = data[b]
      return typeof r === 'string' || typeof r === 'number' ? r : a
    })
  },
  getMapBoxStaticMap([long, lat, zoom = configs.map.zoom]) {
    let geoJSON = helpers.convertGeoJSON({
      type: 'Feature',
      properties: {
        'marker-size': 's',
        'marker-color': '#977564'
      },
      geometry: {
        type: 'Point',
        coordinates: [long, lat]
      }
    })

    return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(${geoJSON})/${long},${lat},${zoom}/${configs.map.staticMapSize}?access_token=${configs.map.key}`
  },
  convertGeoJSON(obj) {
    return encodeURIComponent(JSON.stringify(obj))
  },
  initFormMasonry() {
    let formMasonry = null,
      masonryElement = '',
      itemSelector = '',
      columnWidth = ''

    if (Dom7('.popup-save.modal-in .formContainer').length > 0) {
      masonryElement = document.querySelector('.popup-save.modal-in .formContainer')
      itemSelector = '.form-group-container'
      columnWidth = '.form-group-container'
    }

    if (Dom7('.popup-view.modal-in').length > 0) {
      masonryElement = document.querySelector('.popup-view.modal-in .container')
      itemSelector = '.view-info-container'
      columnWidth = '.view-info-container'
    }

    if (masonryElement != '') {
      formMasonry = new Masonry(masonryElement, {
        itemSelector: itemSelector,
        columnWidth: columnWidth,
        percentPosition: true,
        gutter: 10
        // horizontalOrder: true,
      })
    }

    Dom7('.form-container').removeClass('hidden')
    return formMasonry
  },
  weekNumberToText(weekNumber, year) {
    const startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7)
    const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000)

    const startDay = startDate.getDate()
    const startMonth = startDate.toLocaleString('default', { month: 'long' })

    const endDay = endDate.getDate()
    const endMonth = endDate.toLocaleString('default', { month: 'long' })

    const formattedRange = `${startMonth} ${startDay} - ${endMonth} ${endDay}`

    return formattedRange
  },
  getWeekList(year) {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    let weekNumber = 52 // weeks in a year

    if (year === currentYear) {
      const startOfYear = new Date(year, 0, 1)
      const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000
      weekNumber = Math.ceil((currentDate - startOfYear) / millisecondsInWeek)
    }

    const weekList = Array.from({ length: weekNumber }, (_, index) => index + 1)

    return weekList
  },
  getMonthName(monthNumber) {
    const date = new Date(2000, monthNumber - 1)
    const monthName = date.toLocaleString('default', { month: 'long' })

    return monthName
  },
  getMonthList(year) {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    let monthNumber = 12

    if (year === currentYear) monthNumber = currentDate.getMonth() + 1

    const monthList = Array.from({ length: monthNumber }, (_, index) => index + 1)

    return monthList
  },
  getYearList() {
    // starting year 2023
    const currentYear = new Date().getFullYear()
    const yearList = Array.from({ length: currentYear - 2022 }, (_, index) => 2023 + index)

    return yearList
  }
}

helpers.cloneDeep = cloneDeep

const install = (app) => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers }
