import axios from '@/utils/axios'
import configs from '@/utils/configs'
import helpers from '@/utils/helpers'
import validations from '@/utils/validations'
import translation from '@/utils/translation'
import socket from '@/utils/socket'
import store from '@/utils/store'

export default (app) => {
  app.use(axios)
  app.use(configs)
  app.use(helpers)
  app.use(validations)
  app.use(translation)
  app.use(socket)
  app.use(store)
}
