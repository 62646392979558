<template>
  <f7-popup class="popup-imageupload" :class="[params.type, params.name]" :tablet-fullscreen="false" :backdrop="backdrop">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation('PNL_UPLOAD_IMAGE') }} </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="toggleFullScreen">
            <font-awesome-icon v-if="!isFullScreen" :icon="['far', 'expand']" fixed-width />
            <font-awesome-icon v-if="isFullScreen" :icon="['far', 'compress']" fixed-width />
          </f7-link>

          <f7-link @click="closePopup">
            <font-awesome-icon :icon="['far', 'xmark']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block v-if="imageSource" class="upload-image-container">
        <cropper ref="cropper" class="cropper" :canvas="canvas" :src="imageSource" :stencil-size="stencilSize" :stencil-props="stencilProps" @change="cropperChange"></cropper>

        <div class="button-container">
          <div>
            <f7-button outline large :disabled="isButtonProcessing" @click="rotateImage($refs)">
              <font-awesome-icon :icon="['far', 'arrows-rotate']" fixed-width />
            </f7-button>
            <f7-button outline large :disabled="isButtonProcessing" @click="flipImage($refs)">
              <font-awesome-icon :icon="['far', 'arrow-right-arrow-left']" fixed-width />
            </f7-button>
            <f7-button outline large :disabled="isButtonProcessing" @click="removeImage($refs)">
              <font-awesome-icon :icon="['far', 'trash']" fixed-width />
            </f7-button>
          </div>
          <div>
            <f7-button large :disabled="isButtonProcessing" @click="changeImage($refs)">
              <font-awesome-icon :icon="['far', 'images']" fixed-width />
            </f7-button>
            <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing" @click="useImage"> {{ $t.getTranslation('PNL_USE_IMAGE') }}</f7-button>
          </div>
        </div>
      </f7-block>

      <f7-block v-if="!imageSource" class="upload-image-container select-image">
        <img src="@/assets/images/svg/upload-add.svg" :alt="$t.getTranslation('IMG_ALT_SELECT_IMAGE')" />

        <h1>{{ title || $t.getTranslation('PNL_BROWSE_SELECT_IMAGE') }}</h1>

        <f7-button fill large @click="selectImage($refs)">{{ $t.getTranslation('PNL_SELECT_IMAGE') }}</f7-button>
        <f7-button fill large @click="selectAnimatedImage($refs)">{{ $t.getTranslation('PNL_SELECT_ANIMATED_IMAGE') }}</f7-button>

        <p>
          {{ description || $t.getTranslation('PNL_PLEASE_SELECT_CLEAR_IMAGE') }}
          <strong>{{ $t.getTranslation('PNL_MAXIMUM_UPLOAD_IMAGE_SIZE') }} {{ imageSizeLimit }}MB.</strong>
        </p>
      </f7-block>

      <input ref="fileInput" capture="user" type="file" accept="image/*" @change="loadImage($event)" />
      <input ref="fileInputAnimated" capture="user" type="file" accept="image/*" @change="loadAnimatedImage($event)" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'

import { f7 } from 'framework7-vue'
import { Dom7 } from 'framework7'

import { helpers } from '@/utils/helpers.js'
import { post } from '@/utils/axios'

import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default defineComponent({
  name: 'ImageUploadPopupComponent',
  components: { Cropper },
  props: {
    title: {
      type: String
    },
    description: String,
    imageSizeLimit: Number,
    backdrop: {
      type: Boolean,
      default: true
    },
    stencilSize: Object,
    stencilProps: Object,
    canvas: {
      type: Object,
      default() {
        return { minHeight: 0, minWidth: 0, maxHeight: 1480, maxWidth: 1480 }
      }
    },
    params: Object,
    upload: Object
  },
  emits: ['opened', 'closed', 'cropped'],
  setup(props, { emit }) {
    let mainCoordinates = false
    let mainCanvas = false
    let file = false

    const isFullScreen = ref(false)
    const imageSource = ref(false)
    const imageMimeType = ref('')
    const isButtonProcessing = ref(false)

    onMounted(() => reset)

    const reset = () => {
      imageSource.value = false
      mainCoordinates = false
      mainCanvas = false
    }

    const closePopup = () => {
      isButtonProcessing.value = false
      f7.popup.close('.popup-imageupload')

      emit('closed', true)
      setTimeout(reset, 300)
    }

    const toggleFullScreen = () => {
      isFullScreen.value = !isFullScreen.value

      if (Dom7('.popup.popup-imageupload.modal-in').length > 0) {
        if (isFullScreen.value === true) {
          Dom7('.popup.popup-imageupload.modal-in').addClass('popup-fullscreen')
        } else {
          Dom7('.popup.popup-imageupload.modal-in').removeClass('popup-fullscreen')
        }
      }
    }

    const selectImage = (refs) => {
      refs.fileInput.value = ''
      refs.fileInput.click()
    }

    const selectAnimatedImage = (refs) => {
      refs.fileInput.value = ''
      refs.fileInputAnimated.click()
    }

    const loadImage = (event) => {
      let input = event.target

      if (input?.files?.[0]) {
        file = input?.files?.[0]
        imageMimeType.value = file?.type

        let reader = new FileReader()

        reader.onload = (e) => {
          imageSource.value = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    }

    const loadAnimatedImage = (event) => {
      let input = event.target

      if (input?.files?.[0]) {
        file = input?.files?.[0]
        imageMimeType.value = file?.type

        let reader = new FileReader()

        reader.onload = (e) => {
          let animatedImage = e.target.result

          closePopup()
          emit('cropped', { ...props.params, file: file, image: animatedImage })
        }

        reader.readAsDataURL(input.files[0])
      }
    }

    const changeImage = (refs) => {
      refs.fileInput.click()
    }

    const flipImage = (refs) => {
      refs.cropper.flip(true, false)
    }

    const rotateImage = (refs) => {
      refs.cropper.rotate(-90)
    }

    const removeImage = (refs) => {
      imageSource.value = false
    }

    const useImage = () => {
      let converted = false
      let imageQuality = 0.75

      const toBlob = async (blob) => {
        try {
          let currentSize = blob?.size
          let sizeLimit = props.imageSizeLimit * 1000 * 1024

          if (currentSize > sizeLimit) {
            if (!mainCanvas) return
            isButtonProcessing.value = true
            mainCanvas.toBlob(toBlob, imageMimeType.value, imageQuality)
            if (imageQuality > 0) {
              imageQuality -= 0.1
            }
          } else {
            converted = true
          }

          if (converted) {
            if (props && props?.upload && props?.upload?.link != '' && blob) {
              const saveFormData = new FormData()
              saveFormData.append(`${props?.params?.type || 'Image'}-1${props?.upload?.default ? '-DEFAULT' : ''}`, blob)

              for (let itemKey in props?.upload?.params) {
                saveFormData[itemKey] = props?.upload?.params[itemKey]
              }

              await post(props?.upload?.link, saveFormData)
            }

            closePopup()
            emit('cropped', { ...props.params, blob })
          }
        } catch (error) {
          isButtonProcessing.value = false
          helpers.catchError(error, true)
        }
      }

      if (!mainCanvas) return
      mainCanvas.toBlob(toBlob, imageMimeType.value)
    }

    const cropperChange = ({ coordinates, canvas }) => {
      mainCoordinates = coordinates
      mainCanvas = canvas
    }

    return {
      props,
      isFullScreen,
      isButtonProcessing,
      closePopup,
      toggleFullScreen,
      imageSource,
      selectImage,
      selectAnimatedImage,
      loadImage,
      loadAnimatedImage,
      cropperChange,
      changeImage,
      rotateImage,
      flipImage,
      removeImage,
      useImage
    }
  },
  methods: {
    openPopup(type, name) {
      // if (helpers.checkIsMobile()) {
      //   this.selectImage(this.$refs);
      // } else {
      //   f7.popup.open(`.popup-imageupload${type && !helpers.isBlank(type) ? "." + type : ""}${name && !helpers.isBlank(name) ? "." + name : ""}`);
      // }
      f7.popup.open(`.popup-imageupload${type && !helpers.isBlank(type) ? '.' + type : ''}${name && !helpers.isBlank(name) ? '.' + name : ''}`)
      this.$emit('opened', true)
    }
  }
})
</script>

<style scoped>
.popup.popup-imageupload {
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;

  /* POPUP CONFIG */
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 500px;
  --f7-popup-tablet-height: 100%;
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  transform: translate3d(100vh, 0, 0);
  top: auto;
  left: auto;
  bottom: 0px;
  right: 0px;

  transition: all 300ms ease-in-out;
}
.popup.popup-imageupload.popup-fullscreen {
  --f7-popup-tablet-width: 100%;
  transform: translate3d(100vw, 0, 0);
}
.popup.popup-imageupload.modal-in {
  transform: translate3d(0, 0, 0);
}
.popup.popup-imageupload.modal-out {
  transform: translate3d(100vh, 0, 0);
}
.popup.popup-imageupload .navbar a.link {
  padding: 0 10px;
  min-width: 20px;
}
.popup.popup-imageupload .navbar .title {
  padding-left: 10px;
}
.upload-image-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 70px 0px 0px;
  padding: 20px 50px 0px;
}
.upload-image-container img {
  width: 180px;
}
.upload-image-container h1 {
  font-size: 28px;
  margin: 20px 0px 20px;
  font-weight: 400;
  text-align: center;
}
.upload-image-container .button {
  width: 80%;
  margin: 20px 0px 0px;
}
.upload-image-container p {
  margin: 20px 0px;
  padding: 10px 60px;
  font-size: 14px;
  text-align: center;
}
.upload-image-container p strong {
  display: block;
  margin-top: 20px;
}

input[type='file'] {
  display: none;
}
.button-container {
  display: flex;
  margin: 20px 0px;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}
.button-container div {
  display: flex;
}
.button-container div:first-child {
  justify-content: flex-start;
}
.button-container div:last-child {
  justify-content: flex-end;
}
.button-container .button {
  min-width: 50px;
  margin-right: 5px;
}
.button-container .button svg {
  margin-right: 0px;
}
.button-container .button:last-child {
  margin-right: 0px;
}
.button-container .button-fill {
  min-width: 160px;
}
.cropper {
  width: 460px;
  height: 380px;
}
@media only screen and (max-width: 899px) {
  .upload-image-container img {
    width: 180px;
    margin-top: 60px;
  }
  .upload-image-container h1 {
    font-size: 24px;
    margin: 60px 0px 0px;
  }
  .upload-image-container p {
    padding: 10px 0px;
  }
  .upload-image-container p strong {
    display: block;
    margin-top: 10px;
  }
  .upload-image-container .button-container {
    justify-content: center;
    flex-direction: column;
  }
  .upload-image-container .button-container div {
    justify-content: center;
    margin-bottom: 20px;
  }
}
</style>
