<template>
  <f7-app v-bind="f7params">
    <LeftPanelComponent v-if="!hideLeftPanel" />

    <f7-view class="main-view" main url="/" :init="false"></f7-view>

    <RightPanelComponent v-if="!hideRightPanel" />
  </f7-app>
</template>

<script>
import { getRoutes } from "@/routes";
import { useStore } from "@/store";

import { defineComponent, onMounted, onErrorCaptured, computed, provide } from "vue";

import { Dom7 } from "framework7";
import { f7ready } from "framework7-vue";

import { helpers } from "@/utils/helpers.js";
import { configs } from "@/utils/configs.js";
import { translation } from "@/utils/translation.js";

import LeftPanelComponent from "@/components/LeftPanelComponent.vue";
import RightPanelComponent from "@/components/RightPanelComponent.vue";

export default defineComponent({
  name: "MainApp",
  components: {
    LeftPanelComponent,
    RightPanelComponent,
  },
  setup() {
    provide("$translation", translation);

    const store = useStore();

    getRoutes();
    const routesList = computed(() => store.getters["page/getRoutes"]);

    const f7params = {
      view: {
        browserHistory: true,
        browserHistorySeparator: "",
        transition: "f7-cover",
        preloadPreviousPage: false,
        stackPages: false,
      },
      root: ".main-view",
      name: configs.title,
      id: configs.appId || "app.unique.id",
      language: configs.language || "EN",
      theme: configs.theme || "md",
      dialog: {
        title: configs.title,
        buttonOk: translation.getTranslation("PNL_OK", "Ok"),
        buttonCancel: translation.getTranslation("PNL_CANCEL", "Cancel"),
      },
      notification: {
        title: configs.title,
        closeTimeout: 15000,
      },
    };

    const hideLeftPanel = computed(() => store.getters["page/getActiveRoute"]?.meta?.hideLeftPanel);
    const hideRightPanel = computed(() => store.getters["page/getActiveRoute"]?.meta?.hideRightPanel);

    // FETCH STORE DATA
    (async () => {
      await store.dispatch("config/fetchData", { force: true });
      await store.dispatch("translation/fetchData", { force: true, type: configs?.translation?.type });
      await store.dispatch("socket/joinGlobalRooms");
    })();

    onMounted(() => {
      f7ready((f7) => {
        // CREATE MAIN VIEW
        f7.views.create(".main-view", {
          url: "/",
          routes: routesList.value,
        });

        let popupElement = false;

        f7.on("popupOpened", (element) => {
          if (!Dom7(element.el).hasClass("popup-selecttime")) {
            popupElement = Dom7(".popup.popup-behind");
            popupElement.removeClass("modal-in");

            window.setTimeout(() => {
              Dom7(".toggle-visibility input[type='checkbox']").prop("checked", true);
            }, 300);
          }
        });

        // EVENTS ON POPUP-CLOSE
        f7.on("popupClosed", (element) => {
          if (!Dom7(element.el).hasClass("popup-selecttime")) {
            if (popupElement) {
              popupElement.removeClass("popup-behind");
              popupElement.addClass("modal-in");
              popupElement = false;
            }

            window.setTimeout(() => {
              if (Dom7(".popup.modal-in").length > 0) Dom7(".popup-backdrop").addClass("backdrop-in");
            }, 100);
          }
        });
      });
    });

    onErrorCaptured((error) => {
      helpers.catchError(error, true);
    });

    return { f7params, hideLeftPanel, hideRightPanel };
  },
});
</script>
