// SERVICE PARTNER ROUTES
import dashboard from '@/routes/expert/dashboard.js'
import booking from '@/routes/expert/booking.js'
import calendar from '@/routes/expert/calendar.js'
import setting from '@/routes/expert/setting.js'

const expertRoutes = [
  // START ROUTE DECLARATIONS ORDERED ACCORDINGLY IN NAVIGATIONS
  ...dashboard,
  ...booking,
  ...calendar,
  ...setting
]

export { expertRoutes }
