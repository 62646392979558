import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'profile',
    path: '/profile/',
    meta: {
      navigation: {
        isShown: false,
        icon: 'gear',
        title: translation.getTranslation('PNL_NAV_PROFILE', 'Profile'),
        activeLink: 'profile'
      },
      isSecured: true,
      hasRoles: []
    },
    redirect: '/profile/history/login/',
    routes: [
      {
        name: 'profileHistoryLogin',
        path: 'history/login/',
        meta: {
          self: true,
          navigation: {
            isShown: false,
            title: translation.getTranslation('PNL_NAV_LOGIN_HISTORY', 'Login History'),
            activeLink: 'profileHistoryLogin'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/history/LoginPage.vue')
      }
      // {
      //   name: 'profileHistoryAction',
      //   path: 'history/action/',
      //   meta: {
      //     self: true,
      //     navigation: {
      //       isShown: false,
      //       title: translation.getTranslation('PNL_NAV_ACTION_HISTORY', 'Action History'),
      //       activeLink: 'profileHistoryAction'
      //     },
      //     isSecured: true,
      //     hasRoles: []
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/history/ActionPage.vue')
      // }
    ]
  }
]

export default routes
