<template>
  <f7-popup class="popup-selectgeolocation" :class="inputGroup?.value" :tablet-fullscreen="false" :backdrop="backdrop">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ inputGroup?.title }} </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onToggleFullScreen">
            <font-awesome-icon v-if="!isFullScreen" :icon="['far', 'expand']" fixed-width />
            <font-awesome-icon v-if="isFullScreen" :icon="['far', 'compress']" fixed-width />
          </f7-link>

          <f7-link @click="closePopup">
            <font-awesome-icon :icon="['far', 'xmark']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <div class="map-holder">
        <div id="map"></div>
      </div>

      <div class="footer-button-container">
        <f7-button class="button-select" fill large :disabled="isButtonDisabled" @click="onSelectGeoLocation">{{ inputGroup?.buttonText }}</f7-button>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref } from "vue";
import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";

import { configs } from "@/utils/configs.js";
import { helpers } from "@/utils/helpers";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default defineComponent({
  name: "SelectGeoLocationPopupComponent",
  components: {},
  props: {
    backdrop: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["opened", "closed", "selected"],
  setup(props, { emit }) {
    const MAP_ACCESS_TOKEN = configs.map.key;
    const MAP_CENTER = configs.map.center;
    const MAP_ZOOM = configs.map.zoom;

    const inputGroup = ref({});
    const inputGroupParams = ref({});

    const isFullScreen = ref(false);

    const mapBoxReference = ref(false);
    const mapCenter = ref(MAP_CENTER);
    const mapZoom = ref(MAP_ZOOM);
    const mapMarker = ref(false);

    const isButtonDisabled = ref(true);

    const onToggleFullScreen = () => {
      isFullScreen.value = !isFullScreen.value;

      if (Dom7(".popup.popup-selectgeolocation.modal-in").length > 0) {
        if (isFullScreen.value === true) {
          Dom7(".popup.popup-selectgeolocation.modal-in").addClass("popup-fullscreen");
        } else {
          Dom7(".popup.popup-selectgeolocation.modal-in").removeClass("popup-fullscreen");
        }

        if (mapBoxReference.value != false) {
          window.setTimeout(() => {
            mapBoxReference.value.resize();
          }, 500);
        }
      }
    };

    const onCreateMap = async () => {
      try {
        mapboxgl.accessToken = MAP_ACCESS_TOKEN;

        mapBoxReference.value = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: mapCenter.value,
          zoom: mapZoom.value,
          trackResize: true,
        });

        mapBoxReference.value.on("idle", () => {
          mapZoom.value = mapBoxReference.value.getZoom();
        });

        let geocoder = new MapboxGeocoder({
          accessToken: MAP_ACCESS_TOKEN,
          mapboxgl: mapboxgl,
          marker: false,
          limit: 10,
          trackProximity: false,
          proximity: "",
          language: "",
        });

        geocoder.setProximity(null);

        mapBoxReference.value.addControl(geocoder);

        geocoder.on("result", (e) => {
          if (mapMarker.value) mapMarker.value.remove();

          mapMarker.value = new mapboxgl.Marker({
            draggable: true,
            color: "#977564",
          });

          mapMarker.value.setLngLat(e.result.center);
          mapMarker.value.addTo(mapBoxReference.value);

          mapCenter.value = e.result.center;
          isButtonDisabled.value = false;

          mapMarker.value.on("dragend", (e) => {
            mapCenter.value = Object.values(e.target.getLngLat()); // LONG,LAT
            isButtonDisabled.value = false;
          });
        });
      } catch (error) {
        helpers.catchError(error, true);
      }
    };

    const onSelectGeoLocation = () => {
      f7.popup.close(".popup-selectgeolocation");
      emit("selected", { type: inputGroup.value.value, list: [mapCenter.value[0], mapCenter.value[1], mapZoom.value] });
    };

    return {
      inputGroup,
      inputGroupParams,
      isFullScreen,
      isButtonDisabled,
      onToggleFullScreen,
      mapBoxReference,
      onCreateMap,
      onSelectGeoLocation,
    };
  },
  methods: {
    async openPopup(inputGroup) {
      const self = this;
      self.inputGroup = inputGroup;
      self.inputGroupParams = inputGroup?.params;

      window.setTimeout(() => {
        f7.popup.open(`.popup-selectgeolocation`);

        if (!self.mapBoxReference) self.onCreateMap();
        self.$emit("opened", true);
      }, 10);
    },
    closePopup() {
      f7.popup.close(".popup-selectgeolocation");
      this.$emit("closed", true);
    },
  },
});
</script>

<style scoped>
.popup.popup-selectgeolocation {
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;

  /* POPUP CONFIG */
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 500px;
  --f7-popup-tablet-height: 100%;
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  transform: translate3d(100vh, 0, 0);
  top: auto;
  left: auto;
  bottom: 0px;
  right: 0px;

  transition: all 300ms ease-in-out;
}
.popup.popup-selectgeolocation.popup-fullscreen {
  --f7-popup-tablet-width: 100%;
  transform: translate3d(100vw, 0, 0);
}
.popup.popup-selectgeolocation.modal-in {
  transform: translate3d(0, 0, 0);
}
.popup.popup-selectgeolocation.modal-out {
  transform: translate3d(100vh, 0, 0);
}
.popup.popup-selectgeolocation .navbar a.link {
  padding: 0 10px;
  min-width: 20px;
}
.popup.popup-selectgeolocation .navbar .title {
  padding-left: 10px;
}
.popup-selectgeolocation .map-holder {
  width: 100%;
}
.popup-selectgeolocation #map {
  position: relative;
  width: 100%;
  height: calc(100vh - 135px);
}
.footer-button-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 55px);
  background: #fff;
  padding: 18px 30px;
  z-index: 999;
  border-top: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footer-button-container .button {
  width: 100%;
}
</style>
