import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'calendar',
    path: '/calendar/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'calendar-star',
        title: translation.getTranslation('PNL_NAV_CALENDAR', 'Calendar'),
        activeLink: 'calendar'
      },
      isSecured: true,
      hasRoles: ['ADMIN_CALENDAR']
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/CalendarPage.vue')
  }
]

export default routes
