import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'service',
    path: '/service/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'user-doctor-message',
        title: translation.getTranslation('PNL_NAV_SERVICES', 'Services'),
        activeLink: 'service'
      },
      isSecured: true,
      hasRoles: ['SERVICEPARTNER_SERVICE', 'SERVICEPARTNER_EXPERT']
    },
    redirect: '/service/',

    routes: [
      {
        name: 'serviceList',
        path: 'service/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_SERVICE_LIST', 'Service List'),
            activeLink: 'serviceList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_SERVICE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/service/ServiceListPage.vue')
      },
      {
        name: 'expertList',
        path: 'expert/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_EXPERT_LIST', 'Expert List'),
            activeLink: 'expertList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_EXPERT','SERVICEPARTNER_SERVICE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/expert/ExpertListPage.vue')
      },
      {
        name: 'serviceLocationList',
        path: 'location/list/',
        meta: {
          navigation: {
            isShown: true,
            icon: 'map',
            title: 'Locations',
            title: translation.getTranslation('PNL_NAV_LOCATIONS', 'Locations'),
            activeLink: 'serviceLocationList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_LOCATION']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/servicepartner/ServicePartnerLocationListPage.vue')
      }
    ]
  }
]

export default routes
