import { f7 } from 'framework7-vue'

import { getRoutes } from '@/routes'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { translation } from '@/utils/translation.js'

import { useStore } from '@/store'
const store = useStore()

const login = async (apiLink, formData) => {
  let res = await $HTTP.post(apiLink, formData)

  if (res && res.status === 200 && res?.data?.data && res?.data?.data.Token != '') {
    let returnData = res.data.data
    returnData.TokenStartDate = new Date().getTime()

    await store.dispatch('user/setData', returnData)
    await store.dispatch('user/fetchData')
    // await store.dispatch('socket/joinGlobalRooms')

    if (formData?.loginType) {
      store.dispatch('page/setData', { loginType: formData?.loginType })
    }

    let routes = getRoutes()
    let mainView = f7.views.get('.main-view')
    mainView.destroy()

    mainView = f7.views.create('.main-view', {
      url: '/',
      routes
    })

    return { isLogin: true, mainView }
  }

  return false
}

const checkHasRoles = (Roles) => {
  const userRoles = store.getters['user/getData'].UserRoleList

  if (Roles && Roles.length > 0) {
    let hasRolesCounter = 0

    Roles.forEach((role) => {
      if (userRoles.indexOf(role) > -1) {
        hasRolesCounter++
      }
    })

    return hasRolesCounter === Roles.length
  } else {
    return true
  }
}

const checkHasAnyRoles = (Roles) => {
  const userRoles = store.getters['user/getData'].UserRoleList

  if (Roles && Roles.length > 0) {
    let hasRolesCounter = 0

    Roles.forEach((role) => {
      if (userRoles.indexOf(role) > -1) {
        hasRolesCounter++
      }
    })

    return hasRolesCounter > 0
  } else {
    return true
  }
}

const logout = ({ f7router, loginLink = '/login/' }) => {
  helpers.createConfirmation({
    message: translation.getTranslation('CNF_LOGOUT'),
    confirm: () => {
      store.dispatch('user/resetData')
      store.dispatch('config/resetData')

      if (f7router && loginLink) {
        f7router.navigate(loginLink)
      } else {
        window.location.href = loginLink
      }
    }
  })
}

export { login, logout, checkHasAnyRoles, checkHasRoles }
