import { getNavigationLists } from '@/routes/@common.js'

import { useStore } from '@/store'
const store = useStore()

import { adminRoutes } from '@/routes/admin/@declaration.js'
import { commonRoutes } from '@/routes/common/@declaration.js'
import { servicePartnerRoutes } from '@/routes/servicepartner/@declaration.js'
import { expertRoutes } from '@/routes/expert/@declaration.js'

import notfound from '@/routes/notfound.js'

const mainRoutes = [
  {
    name: '',
    path: '/',
    beforeEnter: [
      ({ router }) => {
        let token = store.getters['user/getToken']
        let loginType = store.getters['page/getData']?.loginType || store.getters['user/getData']?.LoginType || ''

        if (token != '' && loginType != '') {
          return router.navigate('/dashboard/')
        } else {
          return router.navigate('/splash/')
        }
      }
    ]
  }
]

const getRoutes = () => {
  const routes = [...mainRoutes, ...commonRoutes]

  let token = store.getters['user/getToken']
  let loginType = store.getters['page/getData']?.loginType || store.getters['user/getData']?.LoginType || ''

  if (token != '' && loginType === 'ADMIN') {
    routes.push(...adminRoutes)
  }

  if (token != '' && loginType === 'SERVICEPARTNER') {
    routes.push(...servicePartnerRoutes)
  }

  if (token != '' && loginType === 'EXPERT') {
    routes.push(...expertRoutes)
  }

  routes.push(...notfound)
  store.dispatch('page/setData', { routes })

  return routes
}

export { getRoutes, getNavigationLists }
