import { useStore } from '@/store'
import { helpers } from '@/utils/helpers'

const store = useStore()

const translation = {
  getLanguage() {
    let languageCode = store.getters['translation/getData']?.languageCode
    return languageCode
  },
  getTranslation(translationCode, defaultContent = true, data) {
    let languageCode = store.getters['translation/getData']?.languageCode
    let translationContent = store.getters['translation/getData']?.translations?.[languageCode]?.[translationCode]

    if (translationContent) {
      if (translationContent.indexOf('{') > -1 && !helpers.isBlank(data) && !helpers.isEmpty(data)) {
        return helpers.stringInterpolate(translationContent, data)
      }

      return translationContent
    } else {
      if (helpers.isString(defaultContent)) return defaultContent
      return defaultContent ? translationCode : ''
    }
  }
}

const install = (app) => {
  app.config.globalProperties.$t = translation
}

export { install as default, translation }
