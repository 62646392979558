// SHOULD UPDATE environment based on the server
import environment from '@/configs/@environment'

import contact from '@/configs/contact'
import host from '@/configs/host'
import link from '@/configs/link'
import main from '@/configs/main'
import encryption from '@/configs/encryption'
import translation from '@/configs/translation'
import map from '@/configs/map'

let configs = {
  ...environment,
  ...contact,
  ...host,
  ...link,
  ...main,
  ...encryption,
  ...translation,
  ...map
}

// UPDATE CONFIGS BASED ON ENVIRONMENT
configs.appURL = configs.appURLInfo[configs.environment]
configs.panelURL = configs.panelURLInfo[configs.environment]
configs.baseURL = configs.baseURLInfo[configs.environment]
configs.socketURL = configs.socketURLInfo[configs.environment]

for (let key in configs.externalPageLinks) {
  configs.externalPageLinks[key] = configs.baseURL.replace('/api', '/public') + configs.externalPageLinks[key]
}

export default configs
