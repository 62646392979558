import { configs } from '@/utils/configs'
import { io } from 'socket.io-client'

let socketReceiveCallback = {}

const socketMain = io(configs.socketURL, {
  reconnection: true,
  autoConnect: true
})

const socket = {
  emit(emit, data) {
    socketMain.emit(emit, data)
  },
  on(on, data) {
    socketMain.on(on, data)
  },
  unassignSocketCallback(type) {
    delete socketReceiveCallback[type]
  },
  assignSocketCallback(type, cb) {
    socketReceiveCallback[type] = cb
  },
  log(text) {
    console.log(`[SOCKET] ${text}`)
  },
  leave(room) {
    socket.log('Leaving room ' + room)
    socketMain.emit('leave', room)
  },
  join(room) {
    socket.log('Joining room ' + room)
    socketMain.emit('join', room)
  }
}

socketMain.open()

socketMain.on('connect', () => {
  if (!socketMain.connected) return
  socket.log(`Connected`)
})

socketMain.on('connect_error', (error) => {
  socket.log(`Connection failed`, error)
})

socketMain.on('disconnect', () => {
  socket.log(`Disconnected`)
})

socketMain.on('message', (data) => {
  socket.log(`Message`, data)

  if (socketReceiveCallback['RECEIVED_MSG']) {
    socketReceiveCallback['RECEIVED_MSG'](data)
  }
})

const install = (app) => {
  app.config.globalProperties.$socket = socket
}

export { install as default, socket }
