import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'conversation',
    path: '/conversation/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'comments',
        title: translation.getTranslation('PNL_CONVERSATION', 'Conversation'),
        activeLink: 'conversation'
      },
      isSecured: true,
      hasRoles: ['ADMIN_CONVERSATION']
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/conversation/ConversationPage.vue')
  }
]

export default routes
