import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    path: '(.*)',
    meta: {
      activeLink: '',
      isSecured: false,
      hasRoles: [],
      hideLeftPanel: true,
      hideRightPanel: true
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/NotFoundPage.vue')
  }
]

export default routes
