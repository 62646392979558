import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'booking',
    path: '/booking/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'bookmark',
        title: translation.getTranslation('PNL_NAV_BOOKINGS', 'Bookings'),
        activeLink: 'booking'
      },
      isSecured: true,
      hasRoles: ['EXPERT_BOOKING']
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/booking/BookingListPage.vue')
  }
]

export default routes
