import { getRoutes } from '@/routes'
import { translation } from '@/utils/translation.js'

import { useStore } from '@/store'
const store = useStore()

const checkMeta = ({ to, from, resolve, reject, router }) => {
  let metaNavigation = to?.route?.meta?.navigation
  let userToken = store.getters['user/getToken']

  store.dispatch('page/setData', { activeRoute: to.route })

  let breadcrumbsList = []

  breadcrumbsList.push({ title: translation.getTranslation('PNL_NAV_DASHBOARD', 'Dashboard'), icon: 'chart-area', link: '/dashboard/' })

  if (metaNavigation?.title) {
    breadcrumbsList.push({ title: metaNavigation?.title, icon: metaNavigation?.icon || metaNavigation?.parentIcon, link: metaNavigation?.link, isActive: true })
  }

  store.dispatch('page/setBreadcrumbs', breadcrumbsList)

  if (metaNavigation?.activeLink) {
    store.dispatch('page/setData', {
      activeLink: to.route.meta.navigation.activeLink,
      activeLinkParent: to.route.meta.navigation.parentName
    })
  }

  if (to?.route?.meta?.isSecured) {
    if (userToken && userToken !== '') {
      store.dispatch('user/fetchData', { force: false })
    } else {
      store.dispatch('user/resetData')
      reject()

      return router.navigate('/login/')
    }
  }

  let userInfo = store.getters['user/getData']
  let isValid = checkRouteRoles(to.route, userInfo)

  if (isValid) {
    if ((!userToken || userToken == '') && to?.route?.path?.indexOf('login') < 0) {
      reject()
      return router.navigate('/login/')
    } else {
      resolve()
    }
  } else {
    reject()
    if (from?.route?.url) return router.navigate(from?.route?.url)
    return router.navigate('/')
  }

  return true
}

const checkIsAlreadyLogin = ({ to, from, resolve, reject, router }) => {
  const userToken = store.getters['user/getToken']
  let loginType = store.getters['page/getData']?.loginType

  if (userToken !== '' && loginType !== '') {
    reject()
    return router.navigate('/dashboard/')
  }

  resolve()
  return true
}

const checkRouteRoles = (route, userInfo) => {
  let isValid = true

  // CHECK hasAnyRoles => only 1 is required rest is optional
  if (route?.meta?.hasAnyRoles && Array.isArray(route?.meta?.hasAnyRoles) && route?.meta?.hasAnyRoles.length > 0) {
    isValid = false
    let hasRolesCounter = 0

    for (let role of route.meta.hasAnyRoles) {
      if (userInfo?.UserRoleList?.indexOf(role) > -1) {
        hasRolesCounter++
      }
    }

    isValid = hasRolesCounter > 0
  }

  // CHECK hasRoles => everything is required
  if (route?.meta?.hasRoles && Array.isArray(route?.meta?.hasRoles) && route?.meta?.hasRoles.length > 0) {
    isValid = false
    let hasRolesCounter = 0

    for (let role of route.meta.hasRoles) {
      if (userInfo?.UserRoleList?.indexOf(role) > -1) {
        hasRolesCounter++
      }
    }

    isValid = hasRolesCounter === route.meta.hasRoles.length
  }

  return isValid
}

const getNavigationLists = (userInfo) => {
  let navigationLists = []
  let routes = getRoutes()

  for (let route of routes) {
    if (route?.meta?.navigation) {
      let navigation = route?.meta?.navigation
      navigation.child = []
      navigation.name = route?.name
      navigation.link = route?.path

      // CHECK FOR ROLES
      let isValid = checkRouteRoles(route, userInfo)

      if (route?.routes && route?.routes.length > 0) {
        for (let childRoute of route?.routes) {
          if (childRoute?.meta?.navigation) {
            let childRouteNavigation = childRoute?.meta?.navigation

            childRouteNavigation.parentIcon = navigation?.icon
            childRouteNavigation.parentTitle = navigation?.title
            childRouteNavigation.parentName = navigation?.name
            childRouteNavigation.parentLink = navigation?.link

            childRouteNavigation.name = childRoute?.name
            childRouteNavigation.link = `${navigation.link}${childRoute?.path}`

            // CHECK FOR ROLES
            let isValidChildRoute = checkRouteRoles(childRoute, userInfo)

            if (isValidChildRoute) {
              navigation.child.push(childRouteNavigation)
            }
          }
        }
      }

      if (isValid) navigationLists.push(navigation)
    }
  }

  store.dispatch('page/setData', { navigations: navigationLists })
  return navigationLists
}

export { checkMeta, checkIsAlreadyLogin, getNavigationLists }
