<template>
  <f7-popup class="popup-selectmultiple" :class="inputGroup?.value" :tablet-fullscreen="false" :backdrop="true">
    <f7-page infinite :infinite-distance="650" :infinite-preloader="showPreloader" @infinite="loadMore">
      <f7-navbar>
        <f7-nav-title> {{ inputGroup?.title }} </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="toggleFullScreen">
            <font-awesome-icon v-if="!isFullScreen" :icon="['far', 'expand']" fixed-width />
            <font-awesome-icon v-if="isFullScreen" :icon="['far', 'compress']" fixed-width />
          </f7-link>

          <f7-link @click="closePopup">
            <font-awesome-icon :icon="['far', 'xmark']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar v-model:value="searchValue" :disable-button="false" :placeholder="$t.getTranslation('PNL_SEARCH_HERE')" :clear-button="true"></f7-searchbar>

      <div v-if="itemList?.length > 0" class="selectitem-container">
        <template v-for="(item, ind) in itemList" :key="'smpc_' + ind">
          <div class="selectitem" @click="chooseItem(item)">
            <f7-checkbox class="no-ripple" :checked="checkIfSelected(item)" disabled @click.stop.prevent="chooseItem(item)" />
            <img v-if="inputGroupParams?.imageSelector && inputGroupParams?.imageBucket" :src="$h.getImage(item[inputGroupParams?.imageSelector || 'Image'], inputGroupParams?.imageBucket)" :alt="$t.getTranslation('IMG_ALT_ITEM_IMAGE')" />

            <div class="info">
              <h3>{{ item[inputGroupParams?.nameSelector || "name"] }}</h3>
              <p v-if="inputGroupParams?.descriptionSelector">{{ item[inputGroupParams?.descriptionSelector || "description"] || $t.getTranslation("PNL_NO_DESCRIPTION") }}</p>
            </div>
          </div>
        </template>
      </div>

      <NoDataFoundComponent v-else size="sm" />

      <div class="footer-button-container">
        <f7-button v-if="!(inputGroupParams?.limit <= 1)" class="button-toggle" large @click="toggleSelectAll">{{ $t.getTranslation("PNL_TOGGLE_SELECTION") }}</f7-button>
        <f7-button class="button-select" fill large @click="selectItems">{{ inputGroup?.buttonText }}</f7-button>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";

import { get } from "@/utils/axios";

import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

export default defineComponent({
  name: "SelectMultiplePopupComponent",
  components: {
    NoDataFoundComponent,
  },
  props: {
    backdrop: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["opened", "closed", "selected"],
  setup(props, { emit }) {
    let size = 15;
    let page = 1;
    let lastPage = 1;
    let isFetching = false;

    const itemList = ref([]);
    const isFullScreen = ref(false);
    const showPreloader = ref(false);
    const searchValue = ref("");

    const selectedItemList = ref([]);

    const inputGroup = ref({});
    const inputGroupParams = ref({});

    const getList = async (reset) => {
      if (isFetching) return;
      isFetching = true;

      if (reset) {
        page = 1;
        itemList.value = [];
      }

      let returnData = await get(inputGroupParams.value.getLink, {
        page,
        size,
        search: searchValue.value != "" ? searchValue.value : "",
        ...inputGroupParams.value.getParams,
      });

      if (returnData && returnData.data && Array.isArray(returnData.data)) {
        for (let counter = 0; counter < returnData.data.length; counter++) {
          let item = returnData.data[counter];
          itemList.value.push(item);
        }

        lastPage = returnData?.lastPage;
        page++;
      }

      isFetching = false;
    };

    const loadMore = () => {
      return lastPage >= page ? getList() : false;
    };

    watch(searchValue, (newValue, oldValue) => {
      itemList.value = [];

      if (inputGroupParams.value.type == "dynamic") {
        getList(true);
      }

      if (inputGroupParams.value.type === "static" && inputGroupParams.value.list?.length > 0) {
        itemLoop: for (let item of inputGroupParams.value.list) {
          keyLoop: for (let key in item) {
            if (item[key].toLowerCase().indexOf(searchValue.value.toLowerCase()) > -1) {
              itemList.value.push(item);
              continue itemLoop;
            }
          }
        }
      }
    });

    const toggleFullScreen = () => {
      isFullScreen.value = !isFullScreen.value;

      if (Dom7(".popup.popup-selectmultiple.modal-in").length > 0) {
        if (isFullScreen.value === true) {
          Dom7(".popup.popup-selectmultiple.modal-in").addClass("popup-fullscreen");
        } else {
          Dom7(".popup.popup-selectmultiple.modal-in").removeClass("popup-fullscreen");
        }
      }
    };

    const checkIfSelected = (item, returnIndex = false) => {
      if (inputGroupParams.value.valueSelector && item[inputGroupParams.value.valueSelector]) {
        for (let index = 0; index < selectedItemList.value.length; index++) {
          let selectedItem = selectedItemList.value[index];
          if (selectedItem[inputGroupParams.value.valueSelector] == item[inputGroupParams.value.valueSelector]) {
            if (returnIndex) return index;
            return true;
          }
        }
      }

      return false;
    };

    const chooseItem = (item) => {
      let isSelectedIndex = checkIfSelected(item, true);
      if (parseInt(isSelectedIndex) >= 0) {
        selectedItemList.value.splice(isSelectedIndex, 1);
      } else {
        selectedItemList.value.push(item);
      }

      if (inputGroupParams.value.limit && inputGroupParams.value.limit > 0 && selectedItemList.value.length > inputGroupParams.value.limit) {
        selectedItemList.value.shift();
      }
    };

    const toggleSelectAll = () => {
      for (let index = 0; index < itemList.value.length; index++) {
        chooseItem(itemList.value[index]);
      }
    };

    const selectItems = () => {
      f7.popup.close(".popup-selectmultiple");
      emit("selected", { type: inputGroup.value.value, list: selectedItemList.value });
    };

    return {
      isFullScreen,
      toggleFullScreen,
      itemList,
      selectedItemList,
      inputGroup,
      inputGroupParams,
      checkIfSelected,
      chooseItem,
      toggleSelectAll,
      selectItems,
      getList,
      loadMore,
      showPreloader,
      searchValue,
    };
  },
  methods: {
    async openPopup(inputGroup, selectedList) {
      f7.infiniteScroll.create(".popup-selectmultiple .page-content");

      const self = this;

      self.selectedItemList = [];
      self.inputGroup = inputGroup;
      self.inputGroupParams = inputGroup?.params;

      if (self?.inputGroupParams?.type === "dynamic") {
        await self.getList(true);

        if (selectedList?.length > 0) {
          self.selectedItemList = selectedList;
        }
      }

      if (self?.inputGroupParams?.type === "static" && self?.inputGroupParams?.list?.length > 0) {
        self.itemList = self?.inputGroupParams?.list;

        if (selectedList?.length > 0) {
          for (let selectedItem of selectedList) {
            for (let item of self.itemList) {
              if (selectedItem[self?.inputGroupParams?.valueSelector] === item[self?.inputGroupParams?.valueSelector]) {
                self.selectedItemList.push(item);
              }
            }
          }
        }
      }

      window.setTimeout(() => {
        f7.popup.open(`.popup-selectmultiple`);
        self.$emit("opened", true);
      }, 10);
    },
    closePopup() {
      f7.popup.close(".popup-selectmultiple");
      this.$emit("closed", true);
    },
  },
});
</script>

<style scoped>
.popup.popup-selectmultiple {
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;

  /* POPUP CONFIG */
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 500px;
  --f7-popup-tablet-height: 100%;
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  transform: translate3d(100vh, 0, 0);
  top: auto;
  left: auto;
  bottom: 0px;
  right: 0px;

  transition: all 300ms ease-in-out;
}
.popup.popup-selectmultiple.popup-fullscreen {
  --f7-popup-tablet-width: 100%;
  transform: translate3d(100vw, 0, 0);
}
.popup.popup-selectmultiple.modal-in {
  transform: translate3d(0, 0, 0);
}
.popup.popup-selectmultiple.modal-out {
  transform: translate3d(100vh, 0, 0);
}
.popup.popup-selectmultiple .navbar a.link {
  padding: 0 10px;
  min-width: 20px;
}
.popup.popup-selectmultiple .navbar .title {
  padding-left: 10px;
}
.popup.popup-selectmultiple .selectitem-container {
  width: calc(100% - 80px);
  margin: 10px auto;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 60px;
}
.popup.popup-selectmultiple .selectitem-container .selectitem {
  padding: 12px 0px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.popup.popup-selectmultiple .selectitem-container .selectitem .checkbox {
  margin-top: 5px;
  margin-right: 20px;
}
.popup.popup-selectmultiple .selectitem-container .selectitem .checkbox.disabled {
  opacity: 1 !important;
}
.popup.popup-selectmultiple .selectitem-container .selectitem img {
  width: 40px;
  height: auto;
  border-radius: 8px;
  margin-right: 15px;
}
.popup.popup-selectmultiple .selectitem-container .selectitem h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  margin: 0px;
  padding: 0px;
}
.popup.popup-selectmultiple .selectitem-container .selectitem p {
  margin: 0px;
  padding: 0px;
}
.footer-button-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 55px);
  background: #fff;
  padding: 18px 30px;
  z-index: 999;
  border-top: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footer-button-container .checkbox-container {
  font-weight: bold;
  text-transform: uppercase;
}
.footer-button-container .checkbox-container .checkbox {
  margin-right: 10px;
}
.footer-button-container .button {
  width: 100%;
}
.footer-button-container .button.button-toggle {
  width: 320px;
}
.popup.popup-selectmultiple .empty-container {
  margin-top: 60px;
}
</style>
