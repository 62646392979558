import { createApp } from 'vue'
import { registerComponents } from '@/f7components.js'

// CUSTOM STYLING
import '@/assets/css/index.css'

// FULL CALENDAR STYLE
import '@fullcalendar/common/main.css'

// IMPORT ANIMATE CSS
import 'animate.css'

import utils from '@/utils'
import App from '@/App.vue'

const mainApp = createApp(App)
registerComponents(mainApp)
utils(mainApp)

// VUEX Store
import store from '@/store/index.js'
mainApp.use(store)

// REGISTER FONT AWESOME COMPONENTS
import { FontAwesomeIcon } from '@/fontawesome.js'
mainApp.component('FontAwesomeIcon', FontAwesomeIcon)

// MOUNT mainApp
mainApp.mount('#app')
