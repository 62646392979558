import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'marketing',
    path: '/marketing/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'gift-card',
        title: translation.getTranslation('PNL_NAV_MARKETING', 'Marketing'),
        activeLink: 'manage'
      },
      isSecured: true,
      hasAnyRoles: ['SERVICEPARTNER_COUPON', 'SERVICEPARTNER_BUNDLE', 'SERVICEPARTNER_SALE']
      // 'SERVICEPARTNER_BULK', 'SERVICEPARTNER_AMBASSADOR', 'SERVICEPARTNER_FREE'
    },
    redirect: '/marketing/coupon/list/',
    routes: [
      {
        name: 'promoList',
        path: 'promo/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Promotion',
            activeLink: 'promoList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/marketing/CouponListPage.vue')
      },
      {
        name: 'promoUsageList',
        path: 'promousage/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Promotion Usage',
            activeLink: 'promoUsageList'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/marketing/CouponUsageListPage.vue')
      },
      {
        name: 'bundleList',
        path: 'bundle/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_BUNDLE', 'Bundle'),
            activeLink: 'bundleList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_BUNDLE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/marketing/BundleListPage.vue')
      },
      // {
      //   name: 'bulkList',
      //   path: 'bulk/list/',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: translation.getTranslation('PNL_NAV_BULK', 'Bulk'),
      //       activeLink: 'bulkList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['SERVICEPARTNER_BULK']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/marketing/BulkListPage.vue')
      // },
      {
        name: 'saleList',
        path: 'sale/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_SALE', 'Sale'),
            activeLink: 'saleList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_SALE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/marketing/SaleListPage.vue')
      }
      // {
      //   name: 'ambassadorList',
      //   path: 'ambassador/list/',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: translation.getTranslation('PNL_NAV_AMBASSADOR', 'Ambassador'),
      //       activeLink: 'ambassadorList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['SERVICEPARTNER_AMBASSADOR']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/marketing/AmbassadorListPage.vue')
      // },
      // {
      //   name: 'freeList',
      //   path: 'free/list/',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: translation.getTranslation('PNL_NAV_FREE', 'Free'),
      //       activeLink: 'freeList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['SERVICEPARTNER_FREE']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/marketing/FreeListPage.vue')
      // }
    ]
  }
]

export default routes
