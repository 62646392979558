import { checkMeta, checkIsAlreadyLogin } from '@/routes/@common.js'

import { helpers } from '@/utils/helpers.js'

import { useStore } from '@/store'
const store = useStore()

const routes = [
  {
    name: 'login',
    path: '/login/',
    alias: ['/login'],
    meta: {
      navigation: {
        isShown: false
      },
      isSecured: false,
      hideLeftPanel: true,
      hideRightPanel: true,
      hasRoles: []
    },
    redirect: ({ to, resolve, reject }) => {
      let loginLink = '/login/servicepartner/'
      let pageInfo = store.getters['page/getData']

      if (pageInfo?.loginType && !helpers.isBlank(pageInfo?.loginType) && !helpers.isUndefined(pageInfo?.loginType)) {
        loginLink = `/login/${pageInfo?.loginType.toLowerCase()}/`
      }

      store.dispatch('page/resetData')
      resolve(loginLink)
    },
    routes: [
      {
        name: 'loginServicePartner',
        path: 'servicepartner/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin],
        asyncComponent: () => import('@/pages/login/ServicePartnerPage.vue')
      },
      {
        name: 'loginExpert',
        path: 'expert/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin],
        asyncComponent: () => import('@/pages/login/ExpertPage.vue')
      },
      {
        name: 'loginAdmin',
        path: 'admin/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin],
        asyncComponent: () => import('@/pages/login/AdminPage.vue')
      }
    ]
  }
]

export default routes
