import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'userList',
    path: '/user/list/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'users-gear',
        title: translation.getTranslation('PNL_NAV_USER_LIST', 'User List'),
        activeLink: 'userList'
      },
      isSecured: true,
      hasRoles: ['SERVICEPARTNER_USER']
    },
    redirect: '/user/staff/list/',
    routes: [
      // {
      //   name: 'customerList',
      //   path: '/customer/list/',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       icon: 'user-group-crown',
      //       title: translation.getTranslation('PNL_NAV_CUSTOMERS','Customers'),
      //       activeLink: 'customerList'
      //     },
      //     isSecured: true,
      //     hasAnyRoles: ['SERVICEPARTNER_CUSTOMER']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/account/CustomerListPage.vue')
      // },
      {
        name: 'userStaffList',
        path: 'staff/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_USER_STAFF_LIST', 'User/Staff List'),
            activeLink: 'userStaffList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_USER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/servicepartner/ServicePartnerUserListPage.vue')
      }
    ]
  }
]

export default routes
