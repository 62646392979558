import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'setting',
    path: '/setting/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'gear',
        title: translation.getTranslation('PNL_NAV_SETTINGS', 'Settings'),
        activeLink: 'setting'
      },
      isSecured: true,
      hasRoles: ['EXPERT_PROFILE']
    },
    redirect: '/setting/profile/',
    routes: [
      {
        name: 'settingProfile',
        path: 'profile',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_PROFILE', 'Profile'),
            activeLink: 'settingProfile'
          },
          isSecured: true,
          hasRoles: ['EXPERT_PROFILE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/expert/ExpertProfilePage.vue')
      },
      {
        name: 'settingUploadList',
        path: 'upload/list',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_UPLOADS', 'Uploads'),
            activeLink: 'settingUploadList'
          },
          isSecured: true,
          hasRoles: ['EXPERT_PROFILE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/expert/ExpertUploadListPage.vue')
      },
      {
        name: 'settingHistoryLogin',
        path: 'history/login/',
        meta: {
          self: true,
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_LOGIN_HISTORY', 'Login History'),
            activeLink: 'settingHistoryLogin'
          },
          isSecured: true,
          hasRoles: ['EXPERT_PROFILE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/history/LoginPage.vue')
      }
      // {
      //   name: 'settingHistoryAction',
      //   path: 'history/action/',
      //   meta: {
      //     self: true,
      //     navigation: {
      //       isShown: true,
      //       title: translation.getTranslation('PNL_NAV_ACTION_HISTORY', 'Action History'),
      //       activeLink: 'settingHistoryAction'
      //     },
      //     isSecured: true,
      //     hasRoles: ['EXPERT_PROFILE']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/history/ActionPage.vue')
      // }
    ]
  }
]

export default routes
