import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'account',
    path: '/account/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'user-group-crown',
        title: translation.getTranslation('PNL_NAV_ACCOUNTS', 'Accounts'),
        activeLink: 'account'
      },
      isSecured: true,
      hasAnyRoles: ['ADMIN_USER', 'ADMIN_CUSTOMER', 'ADMIN_ADMIN']
    },
    redirect: '/manage/user/list/',
    routes: [
      {
        name: 'userList',
        path: 'user/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_USER_LIST', 'User List'),
            activeLink: 'userList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_USER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/account/UserListPage.vue')
      },
      {
        name: 'customerList',
        path: 'customer/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_CUSTOMER_LIST', 'Customer List'),
            activeLink: 'customerList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_CUSTOMER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/account/CustomerListPage.vue')
      },
      {
        name: 'adminList',
        path: 'admin/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_ADMIN_LIST', 'Admin List'),
            activeLink: 'adminList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_ADMIN']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/account/AdminListPage.vue')
      },
      {
        name: 'ambassadorList',
        path: 'ambassador/list',
        meta: {
          navigation: {
            isShown: true,
            icon: 'person-booth',
            title: 'Ambassador List',
            activeLink: 'ambassador'
          }
        },
        isSecured: true,
        hasRoles: ['ADMIN_AMBASSADOR'],
        asyncComponent: () => import('@/pages/account/AmbassadorListPage.vue')
      }
    ]
  }
]

export default routes
