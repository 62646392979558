<template>
  <f7-popup id="editPaymentInformationPopup" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="true">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent title="Update Payment Information" @close-popup="closePopup" />
      </template>

      <FormComponent id="UpdatePaymentInformationPartial" ref="formComponent" @success-save="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, inject } from 'vue'
import { f7 } from 'framework7-vue'

import { helpers } from '@/utils/helpers.js'
import { useStore } from '@/store'
const store = useStore()

import NavigationPopupSideComponent from '@/components/NavigationPopupSideComponent.vue'
import FormComponent from '@/components/FormComponent.vue'

export default defineComponent({
  name: 'UpdatePaymentInformationPartial',
  components: {
    NavigationPopupSideComponent,
    FormComponent
  },
  props: {},
  emits: ['opened', 'closed'],
  setup(props, { emit }) {
    const t = inject('$translation')

    const formComponentData = {
      formId: 'UpdatePaymentInformationPartial',
      buttonText: '',
      getLink: '/backend/paymentdetail/info',
      getParams: {},
      validation: 'payment',
      saveLink: '/backend/paymentdetail/save',
      saveParams: {},
      successUpdateMessage: 'Successfully updated payment information.'
    }

    const defaultInputList = [
      {
        name: 'Payment Information',
        type: 'input',
        isRow: 'true',
        required: true,
        list: [
          { name: 'Name of Bank', type: 'text', value: 'BankName', required: false, rowWidth: 'col-100' },
          { name: 'Account Name', type: 'text', value: 'BankAccountName', required: false, rowWidth: 'col-100' },
          { name: 'Account Number', type: 'text', value: 'BankAccountNumber', required: false, rowWidth: 'col-100' },
          { name: 'Swift Code', type: 'text', value: 'SwiftCode', required: false, rowWidth: 'col-100' }
        ]
      }
    ]

    return { formComponentData, defaultInputList }
  },
  methods: {
    async openPopup() {
      const self = this

      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList)
      self.formComponentData.buttonText = self.$t.getTranslation('PNL_UPDATE')

      await self.$refs.formComponent.setFormComponentData(self.formComponentData, true)

      f7.popup.open('#editPaymentInformationPopup')
      self.$emit('opened', true)
    },
    closePopup(data) {
      const self = this

      // if (data?.isSave) {
      //   store.dispatch('user/fetchData', { force: true })
      // }

      f7.popup.close('#editPaymentInformationPopup')
      self.$emit('closed', data)
    }
  }
})
</script>
