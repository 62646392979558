import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'history',
    path: '/history/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'list',
        title: translation.getTranslation('PNL_NAV_HISTORY', 'History'),
        activeLink: 'history'
      },
      isSecured: true,
      hasAnyRoles: ['ADMIN_HISTORY_LOGIN', 'ADMIN_HISTORY_ACTION']
    },
    redirect: '/history/login/',
    routes: [
      {
        name: 'historyLogin',
        path: 'login/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_LOGIN_HISTORY', 'Login History'),
            activeLink: 'historyLogin'
          },
          isSecured: true,
          hasRoles: ['ADMIN_HISTORY_LOGIN']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/history/LoginPage.vue')
      },
      {
        name: 'historyAction',
        path: 'action/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_ACTION_HISTORY', 'Action History'),
            activeLink: 'historyAction'
          },
          isSecured: true,
          hasRoles: ['ADMIN_HISTORY_ACTION']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/history/ActionPage.vue')
      }
    ]
  }
]

export default routes
