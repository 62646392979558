// ADMIN ROUTES
import dashboard from '@/routes/admin/dashboard.js'
import payment from '@/routes/admin/payment.js'
import booking from '@/routes/admin/booking.js'
import calendar from '@/routes/admin/calendar.js'
import conversation from '@/routes/admin/conversation.js'
import manage from '@/routes/admin/manage.js'
import expert from '@/routes/admin/expert.js'
import servicePartner from '@/routes/admin/servicepartner.js'
import service from '@/routes/admin/service.js'
import marketing from '@/routes/admin/marketing.js'
import account from '@/routes/admin/account.js'
import history from '@/routes/admin/history.js'
import setting from '@/routes/admin/setting.js'

const adminRoutes = [
  // START ROUTE DECLARATIONS ORDERED ACCORDINGLY IN NAVIGATIONS
  ...dashboard,
  ...payment,
  ...booking,
  ...calendar,
  ...conversation,
  ...manage,
  ...expert,
  ...servicePartner,
  ...service,
  ...marketing,
  ...account,
  ...history,
  ...setting
]

export { adminRoutes }
