import dayjs from 'dayjs'

import { get, post } from '@/utils/axios'
import { helpers } from '@/utils/helpers'

const data = {
  languageCode: 'EN',
  translations: {},
  expirationDate: ''
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getLanguage: (state) => {
    return state.data.languageCode
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  setLanguage: async (context, payload) => {
    context.commit('UPDATE_DATA', { languageCode: payload.languageCode })

    if (payload?.isSaveUserLanguage) {
      await post(`/user/update/language`, { LanguageCode: payload.languageCode })
    }
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  fetchData: async (context, payload) => {
    const expirationDate = context.state.data.expirationDate
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (payload?.force) {
      isExpired = true
    }

    if (expirationDate == '' || isExpired) {
      try {
        const translationList = await get(`/public/translation/list?LanguageCode=${context.state.data.languageCode}&Type=${payload?.type}`)
        // prettier-ignore
        let expirationDate = dayjs().add(1, 'hour').valueOf()

        let translations = context.state.data.translations
        translations[context.state.data.languageCode] = translationList

        context.commit('UPDATE_DATA', { translations, expirationDate })
      } catch (err) {
        helpers.catchError(err, true)
      }
    } else {
      return
    }
  }
}

export const translation = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
