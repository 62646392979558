import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'splash',
    path: '/splash/',
    meta: {
      activeLink: '',
      isSecured: false,
      hasRoles: [],
      hideLeftPanel: true,
      hideRightPanel: true
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/SplashPage.vue')
  }
]

export default routes
