import { socket as Socket } from '@/utils/socket.js'

const data = {
  ConversationKey: null
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  joinGlobalRooms: ({ rootGetters }) => {
    if (rootGetters['user/getData']?.UserKey) Socket.join(rootGetters['user/getData']?.UserKey)
  },
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  }
}

export const socket = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
