<template>
  <f7-panel id="rightPanel" right reveal :visible-breakpoint="2600">
    <f7-page>
      <f7-navbar>
        <f7-nav-left> </f7-nav-left>
        <f7-nav-right>
          <f7-link class="close" panel-toggle="#rightPanel">
            <font-awesome-icon :icon="['far', 'xmark']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <div class="profile-container">
        <div class="profile-image">
          <img :src="$h.getImage(userInfo?.Image, 'USER')" :alt="$t.getTranslation('IMG_ALT_PROFILE_IMAGE')" />
        </div>

        <div class="info">
          <h1>
            {{ userInfo?.FirstName }}
            <span>{{ userInfo?.LastName }}</span>
          </h1>

          <h2>{{ userInfo?.Username }}</h2>
        </div>
      </div>

      <f7-list class="no-hairlines no-hairlines-between list-settings">
        <f7-list-item divider>{{ $t.getTranslation('PNL_PRIMARY_INFORMATION') }}</f7-list-item>
        <f7-list-item :title="$t.getTranslation('PNL_EDIT_PROFILE')" link="#" @click="onEditProfile"></f7-list-item>
        <f7-list-item :title="$t.getTranslation('PNL_UPDATE_CONTACT_INFORMATION')" link="#" @click="onUpdateContactInformation"></f7-list-item>
        <f7-list-item :title="$t.getTranslation('PNL_UPDATE_PROFILE_PICTURE')" link="#" @click="onUpdateProfilePicture"></f7-list-item>
        <!-- TO DO: add transation -->
        <f7-list-item v-if="isServicePartner" title="Update Payment Information" link="#" @click="onUpdatePaymentInformation"></f7-list-item>

        <f7-list-item divider>{{ $t.getTranslation('PNL_ACCOUNT_SECURITY') }}</f7-list-item>
        <f7-list-item :title="$t.getTranslation('PNL_CHANGE_PASSWORD')" link="#" @click="onChangePassword"></f7-list-item>

        <f7-list-item divider>{{ $t.getTranslation('PNL_ACCOUNT_HISTORY') }}</f7-list-item>
        <!-- <f7-list-item :title="$t.getTranslation('PNL_ACTIONS_HISTORY')" link="/profile/history/action/" panel-toggle="#rightPanel" @click="closePopup"></f7-list-item> -->
        <f7-list-item :title="$t.getTranslation('PNL_LOGIN_HISTORY')" link="/profile/history/login/" panel-toggle="#rightPanel" @click="closePopup"></f7-list-item>

        <f7-list-item divider>{{ $t.getTranslation('PNL_OTHERS') }}</f7-list-item>
        <f7-list-item :title="$t.getTranslation('PNL_CHANGE_LANGUAGE')" smart-select :smart-select-params="smartSelectParams" class="languageSelector">
          <select name="languageList">
            <option v-for="language in languageList" :key="'lg_' + language.Code" :value="language.Code" :selected="currentLanguageCode == language.Code">{{ language.Name }}</option>
          </select>
        </f7-list-item>
      </f7-list>

      <f7-button fill large raised class="btn-logout" panel-toggle="#rightPanel" @click="onLogout">{{ $t.getTranslation('PNL_LOGOUT') }}</f7-button>

      <ImageUploadPopupComponent
        ref="imageUploadPopupComponent"
        :params="{ type: 'Image', name: 'RightPanelUpdateProfile' }"
        :image-size-limit="1"
        :canvas="{ maxHeight: 600, maxWidth: 600 }"
        :stencil-props="{ aspectRatio: 1 }"
        :upload="{ link: '/user/update/image', default: true }"
        :backdrop="true"
        @cropped="onImageUploaded"
      />

      <EditProfilePartial ref="editProfilePartial" />
      <UpdateContactInformationPartial ref="updateContactInformationPartial" />
      <ChangePasswordPartial ref="changePasswordPartial" />
      <UpdatePaymentInformationPartial ref="updatePaymentInformationPartial" />
    </f7-page>
  </f7-panel>
</template>

<script>
import { defineComponent, computed } from 'vue'

import { useStore } from '@/store'
import { logout } from '@/logics/auth.js'

import ImageUploadPopupComponent from '@/components/ImageUploadPopupComponent.vue'

import EditProfilePartial from '@/partials/profile/EditProfilePartial.vue'
import UpdateContactInformationPartial from '@/partials/profile/UpdateContactInformationPartial.vue'
import ChangePasswordPartial from '@/partials/profile/ChangePasswordPartial.vue'
import UpdatePaymentInformationPartial from '@/partials/profile/UpdatePaymentInformationPartial.vue'

export default defineComponent({
  name: 'RightPanelComponent',
  components: {
    ImageUploadPopupComponent,
    // PARTIALS
    EditProfilePartial,
    UpdateContactInformationPartial,
    ChangePasswordPartial,
    UpdatePaymentInformationPartial
  },
  setup(props) {
    const store = useStore()
    const userInfo = computed(() => store.getters['user/getData'])

    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const languageList = computed(() => store.getters['config/getData']?.languageList)
    const loginType = store.getters['page/getData']?.loginType
    const isServicePartner = loginType === 'SERVICEPARTNER' ? true : false

    const onLogout = logout

    const onImageUploaded = () => {
      store.dispatch('user/fetchData', { force: true })
    }

    const smartSelectParams = {
      openIn: 'popover',
      routableModals: false,
      closeOnSelect: true,
      on: {
        closed: async (smartSelect) => {
          let languageCode = smartSelect?.$selectEl?.val()

          await store.dispatch('translation/setLanguage', { languageCode, isSaveUserLanguage: true })
          await store.dispatch('translation/fetchData', { force: true, type: configs?.translation?.type })

          window.location.reload()
        }
      }
    }

    return {
      userInfo,
      onLogout,
      currentLanguageCode,
      languageList,
      smartSelectParams,
      onImageUploaded,
      isServicePartner
    }
  },
  methods: {
    onEditProfile() {
      const self = this
      self.$refs.editProfilePartial.openPopup()
    },
    onUpdateContactInformation() {
      const self = this
      self.$refs.updateContactInformationPartial.openPopup()
    },
    onUpdateProfilePicture() {
      this.$refs.imageUploadPopupComponent.openPopup(false, 'RightPanelUpdateProfile')
    },
    onChangePassword() {
      const self = this
      self.$refs.changePasswordPartial.openPopup()
    },
    onUpdatePaymentInformation() {
      const self = this
      self.$refs.updatePaymentInformationPartial.openPopup()
    }
  }
})
</script>
