const data = {
  activeLink: '',
  activeLinkParent: '',
  activeRoute: {},
  activeNavigation: {},
  breadcrumbs: [],
  routes: [],
  navigations: [],
  getLinks: [],
  loginType: 'SERVICEPARTNER'
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getActiveLink: (state) => {
    return state.data.activeLink
  },
  getActiveLinkParent: (state) => {
    return state.data.activeLinkParent
  },
  getActiveRoute: (state) => {
    return state.data.activeRoute
  },
  getActiveNavigation: (state) => {
    return state.data.activeNavigation
  },
  getNavigations: (state) => {
    return state.data.navigations
  },
  getRoutes: (state) => {
    return state.data.routes
  },
  getBreadcrumbs: (state) => {
    return state.data.breadcrumbs
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  addFetchingLink: ({ state, commit, dispatch }, payload) => {
    const getLinks = state.data.getLinks
    commit('UPDATE_DATA', payload)
  },
  setBreadcrumbs: (context, payload) => {
    context.commit('UPDATE_DATA', { breadcrumbs: payload })
  }
}

export const page = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
